<template>
  <v-container>
    <v-row>
      <NavBar></NavBar>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-text-field
          outlined
          v-on:keyup="searchCustomers()"
          v-model="searchName"
          name="searchName"
          label="Πληκτρολόγησε Όνομα ή Επίθετο"
        ></v-text-field>
      </v-col>
      <v-col cols="2"></v-col>
      <v-col cols="2">
        <v-btn @click="viewItem(null)" color="success"
          >ΕΙΣΑΓΩΓΗ ΝΕΟΥ ΠΕΛΑΤΗ</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-data-table
          :headers="customersHeaders"
          :items="this.getAllCustomers"
          :items-per-page="-1"
          :loading="loadCustomers"
          loading-text="Παρακαλώ περιμένετε..."
          class="elevation-1"
        >
          <template v-slot:[`item.view`]="{ item }">
            <v-icon small class="mr-2" @click="viewItem(item)">
              mdi-eye
            </v-icon>
          </template>

          <template v-slot:[`item.delete`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="deleteItemConfirmationMessage(item)"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:[`item.interestedOnTheseTrips`]="{ item }">
            <div v-for="trip in item.interestedOnTheseTrips" :key="trip.tripId">
              {{ trip.tripName }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="justify-center"
          >Θέλεις σίγουρα να διαγράψεις τον συγκεκριμένο χρήστη;</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="deleteItemYes">yes</v-btn>
          <v-btn color="error" text @click="closeDeleteDialog">no</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import NavBar from "@/components/NavBar.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { NavBar },

  data: () => ({
    loadCustomers: true,
    dialogDelete: false,
    itemToBeDeleted: {},
    searchName: "",
    customersHeaders: [
      {
        text: "Εκδομές",
        align: "center",
        sortable: false,
        value: "interestedOnTheseTrips",
        class: "subtitle-2 font-weight-bold",
      },
      {
        text: "Υποβλήθηκε",
        align: "center",
        sortable: false,
        value: "creationDate",
        class: "subtitle-2 font-weight-bold",
      },
      {
        text: "Όνομα",
        align: "center",
        sortable: false,
        value: "firstName",
        class: "subtitle-2 font-weight-bold",
      },
      {
        text: "Επίθετο",
        align: "center",
        sortable: false,
        value: "lastName",
        class: "subtitle-2 font-weight-bold",
      },
      {
        text: "Email",
        align: "center",
        sortable: false,
        value: "email",
        class: "subtitle-2 font-weight-bold",
      },
      {
        text: "Κινητό Τηλέφωνο",
        align: "center",
        sortable: false,
        value: "mobilePhone",
        class: "subtitle-2 font-weight-bold",
      },
      // {
      //   text: "Ημ/νία Εκδρομής",
      //   align: "center",
      //   sortable: false,
      //   value: "tripDate",
      //   class: "subtitle-2 font-weight-bold",
      // },
      {
        text: "Σημειώσεις",
        align: "center",
        sortable: false,
        value: "notes",
        class: "subtitle-2 font-weight-bold",
      },
      {
        text: "Αριθμός Ενηλίκων",
        align: "center",
        sortable: false,
        value: "enilikes",
        class: "subtitle-2 font-weight-bold",
      },
      {
        text: "Αριθμός Ανηλίκων",
        align: "center",
        sortable: false,
        value: "anilikoi",
        class: "subtitle-2 font-weight-bold",
      },
      {
        text: "Προβολή",
        value: "view",
        align: "center",
        sortable: false,
        class: "subtitle-2 font-weight-bold",
      },
      {
        text: "Διαγραφή",
        value: "delete",
        align: "center",
        sortable: false,
        class: "subtitle-2 font-weight-bold",
      },
    ],
  }),

  mounted() {
    this.fetchAllCustomers();
  },

  computed: {
    ...mapGetters("customers", ["getAllCustomers"]),
  },

  methods: {
    ...mapActions("customers", [
      "fetchAllCustomersAction",
      "deleteCustomerAction",
      "searchCustomersByLastOrFirstName",
    ]),

    async fetchAllCustomers() {
      await this.fetchAllCustomersAction();
      this.getAllCustomers;
      this.loadCustomers = false;
    },

    viewItem(item) {
      if (item == null) {
        this.$router.push({
          name: "Customer",
          params: {
            customerId: 0,
          },
        });
      } else {
        this.$router.push({
          name: "Customer",
          params: {
            customerId: item.id,
          },
        });
      }
    },

    deleteItemConfirmationMessage(item) {
      this.itemToBeDeleted = item;
      this.dialogDelete = true;
    },

    deleteItemYes() {
      this.deleteCustomerAction(this.itemToBeDeleted);
      this.closeDeleteDialog();
    },

    closeDeleteDialog() {
      this.dialogDelete = false;
    },

    searchCustomers() {
      if (this.searchName == "") {
        this.fetchAllCustomers();
      } else if (this.searchName.length > 0) {
        this.searchCustomersByLastOrFirstName(this.searchName);
      }
    },
  },
};
</script>

<style scoped>
</style>
