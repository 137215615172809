import axios from 'axios';
import store from '@/state/store.js'

export default {
    namespaced: true,
    state: {
        allCourses: [],
        selectedCourse: {}
    },

    mutations: {
        setCourses(state, allCourses) {
            state.allCourses = allCourses;
        },
        setAllStatementsByPersonId(state, allStatementsByPersonId) {
            state.allStatementsByPersonId = allStatementsByPersonId;
        },
        setNewCourse(state, newCourseDetails) {
            state.selectedCourse = newCourseDetails;
        },
        updateCourse(state, updatedCourseData) {
            state.selectedCourse = updatedCourseData;
        },
        setSelectedCourse(state, selectedCourse) {
            state.selectedCourse = selectedCourse;
        },
    },

    actions: {

        fetchAllCourses: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/courses")
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setCourses", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fetchAllStatementsByPersonId: ({ commit }, personId) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/statements/person/" + personId)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setAllStatementsByPersonId", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        searchCoursesByName: ({ commit }, searchName) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/courses/search/" + searchName)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setCourses", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createCourse({ commit }, newCourseDetails) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .post('/courses', newCourseDetails)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('setNewCourse', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fetchCourseById: ({ commit }, courseId) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/courses/" + courseId)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setSelectedCourse", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        updateCourse({ commit }, courseDetails) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .post('/courses/' + courseDetails.id, courseDetails)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('updateCourse', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }


    },

    getters: {
        getAllCourses: state => state.allCourses,
        getSelectedCourse: state => state.selectedCourse
    },
}