import axios from 'axios';
import store from '@/state/store.js'

export default {
    namespaced: true,
    state: {
        allMajors: []
    },

    mutations: {
        setMajors(state, allMajors) {
            state.allMajors = allMajors;
        }
    },

    actions: {

        fetchAllMajors: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/majors")
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setMajors", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

    },

    getters: {
        getAllMajors: state => state.allMajors
    },
}