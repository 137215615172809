import axios from 'axios';
import store from '@/state/store.js'

export default {
    namespaced: true,
    state: {
        allSemesters: [],
    },

    mutations: {
        setSemesters(state, allSemesters) {
            state.allSemesters = allSemesters;
        },
    },

    actions: {

        fetchAllSemestersAction: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/semesters")
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setSemesters", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }

    },

    getters: {
        getAllSemesters: state => state.allSemesters
    },
}