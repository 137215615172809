import axios from 'axios';
import store from '@/state/store.js'

export default {
    namespaced: true,
    state: {
        plansByMajorId: [],
        plansByCourseId: [],
        plans: []
    },

    mutations: {
        setPlansByMajorId(state, plansByMajorId) {
            state.plansByMajorId = plansByMajorId;
        },
        setPlansByCourseId(state, plansByCourseId) {
            state.plansByCourseId = plansByCourseId;
        },
        setNewPlansCreatedForCourse(state, plans) {
            state.plans = plans;
        },
        setPlans(state, plans) {
            state.plans = plans;
        },
        setSelectedPlan(state, selectedPlan) {
            state.selectedPlan = selectedPlan;
        },
    },

    actions: {

        fetchAllPlans: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/plans")
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setPlans", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fetchPlanById: ({ commit }, planId) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/plans/" + planId)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setSelectedPlan", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fetchPlansByMajorId: ({ commit }, majorId) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/plans/major/" + majorId)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setPlansByMajorId", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fetchPlansByCourseIdAction: ({ commit }, courseId) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/plans/course/" + courseId)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setPlansByCourseId", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        assignCourseToMajorsAction({ commit }, currentPlanDetails) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .post('/plans/assignCourseToMajors', currentPlanDetails)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('setNewPlansCreatedForCourse', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

    },

    getters: {
        getPlansByMajorId: state => state.plansByMajorId,
        getPlansByCourseIdGetter: state => state.plansByCourseId,
        getAllPlans: state => state.plans,
        getSelectedPlan: state => state.selectedPlan
    },
}