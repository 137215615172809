/* eslint-disable no-unused-vars */

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/state/store.js'
import Crm from '@/components/Crm.vue'
import Login from '@/components/Login.vue'
import Customers from '@/components/Customers.vue'
import Trips from '@/components/Trips.vue'
import Customer from '@/components/Customer.vue'
import Trip from '@/components/Trip.vue'
import AlterExploringTripsForm from '@/components/AlterExploringTripsForm.vue'

Vue.use(VueRouter)

const routes = [{
        path: "/",
        component: AlterExploringTripsForm,
        name: "alter_exploring_form",
    },
    {
        path: "/login",
        component: Login,
        name: "login",
        beforeEnter(to, from, next) {
            if (store.state.user.token !== null) {
                next("/crm");
            } else {
                next();
            }
        },
    },
    {
        path: "/crm",
        component: Crm,
        name: "Crm",
        beforeEnter(to, from, next) {
            if (
                store.state.user.userRole === "ROLE_ADMIN" &&
                store.state.user.token !== null
            ) {
                next();
            } else {
                next("/login");
            }
        },
    },
    {
        path: "/customers",
        component: Customers,
        name: "Customers",
        beforeEnter(to, from, next) {
            if (
                store.state.user.userRole === "ROLE_ADMIN" &&
                store.state.user.token !== null
            ) {
                next();
            } else {
                next("/login");
            }
        },
    },
    {
        path: "/trips",
        component: Trips,
        name: "Trips",
        beforeEnter(to, from, next) {
            if (
                store.state.user.userRole === "ROLE_ADMIN" &&
                store.state.user.token !== null
            ) {
                next();
            } else {
                next("/login");
            }
        },
    },
    {
        path: "/customer/:customerId",
        component: Customer,
        props: true,
        name: "Customer",
        beforeEnter(to, from, next) {
            if (
                store.state.user.userRole === "ROLE_ADMIN" &&
                store.state.user.token !== null
            ) {
                next();
            } else {
                next("/login");
            }
        },
    },
    {
        path: "/trip/:tripId",
        component: Trip,
        props: true,
        name: "Trip",
        beforeEnter(to, from, next) {
            if (
                store.state.user.userRole === "ROLE_ADMIN" &&
                store.state.user.token !== null
            ) {
                next();
            } else {
                next("/login");
            }
        },
    }
]

const router = new VueRouter({
    mode: "history",
    routes,
    base: "/"
})

router.beforeEach((to, from, next) => {
    next();
});

export default router