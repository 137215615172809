<template>
  <div class="mt-1">
    <NavBar></NavBar>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="12" lg="8">
          <v-img src="@/assets/alterexploringlogo.jpg"></v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <i>
            Συμπληρώστε στοιχεία, επιλέξτε δράση/δράσεις που θέλετε και στείλτε τη φόρμα συμμετοχής. Αφού λάβετε email
            με βεβαίωση ότι υπάρχουν διαθέσιμες θέσεις, κλείστε τη θέση σας με καταβολή του ποσού, σύμφωνα με τις
            οδηγίες.
          </i>
        </v-col>
      </v-row>

      <v-form ref="form" v-model="valid" class="mt-5">
        <v-row justify="center" align="center">
          <v-col cols="6" sm="3" md="3">
            <!-- <b
              >Όνομα <br />
              First Name</b
            > -->
            <v-text-field label="Όνομα" dense :rules="myRules.selectField" required v-model="selectedCustomer.firstName"
              name="selectedCustomerFirstName" filled>
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <!-- <b>Επίθετο<br />Last Name</b> -->
            <v-text-field label="Επίθετο" dense :rules="myRules.selectField" required filled
              v-model="selectedCustomer.lastName" name="selectedCustomerLastName">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="6" sm="3" md="3">
            <!-- <b>Email</b> -->
            <v-text-field label="Email" dense :rules="myRules.selectField" filled v-model="selectedCustomer.email"
              name="selectedCustomerEmail">
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <!-- <b
              >Κινητό<br />
              Mobile Phone</b
            > -->
            <v-text-field label="Κινητό Τηλέφωνο" dense filled :rules="myRules.selectField"
              v-model="selectedCustomer.mobilePhone" name="selectedCustomerMobilePhone">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-select filled required :rules="myRules.selectField" v-model="selectedCustomer.interestedOnTheseTrips"
              multiple :items="tripsWithActiveStatus" item-text="tripName" item-value="tripId" chips
              label="Επιλέξτε Ημερομηνία Περιήγησης" dense return-object>
            </v-select>
          </v-col>
        </v-row>

        <v-row justify="center" align="center">
          <v-col cols="6" sm="3" md="3">
            <!-- <b
              >Σημειώσεις<br />
              Notes...</b
            > -->
            <v-textarea label="Σημειώσεις" rows="9" filled v-model="selectedCustomer.notes" :counter="255"></v-textarea>
          </v-col>

          <v-col cols="6" sm="3" md="3">
            <!-- <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="selectedCustomer.tripDate"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label='Ημ/νία Περιήγησης'
                  dense
                  :rules="myRules.selectField"
                  filled
                  v-bind="attrs"
                  v-on="on"
                  v-model="selectedCustomer.tripDate"
                >
                </v-text-field>
              </template>
<v-date-picker no-title scrollable v-model="formatedDate">
  <v-spacer></v-spacer>
  <v-btn text color="primary" @click="menu = false">
    Cancel
  </v-btn>
  <v-btn text color="primary" @click="$refs.menu.save(selectedCustomer.tripDate)">
    OK
  </v-btn>
</v-date-picker>
</v-menu> -->
            <!-- <b
              >Αριθμός Ενηλίκων<br />
              Number of Adults</b
            > -->
            <v-text-field label="Αριθμός Ενηλίκων" dense type="number" filled :rules="myRules.allFields"
              v-model="selectedCustomer.enilikes"></v-text-field>
            <!-- <b
              >Αριθμός Ανηλίκων<br />
              Number of Minors</b
            > -->
            <v-text-field label="Αριθμός Ανηλίκων (από 16 ετών και άνω)" dense filled
              v-model="selectedCustomer.anilikoi" type="number"></v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row justify="center" align="center">
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              label='ΑΦΜ (για την απόδειξη)'
              dense                            
              v-model="selectedCustomer.afm"
              name="selectedCustomerAfm"
              filled
            >
            </v-text-field>
          </v-col>
        </v-row> -->

        <v-row justify="center" align="center">
          <v-btn v-if="this.$route.params.studentId != 0" @click="saveCustomer()" color="warning"
            :loading="this.loading" :disabled="!valid || hasVoted">ΑΠΟΣΤΟΛΗ</v-btn>
        </v-row>
      </v-form>

      <v-row justify="center" align="center" class="mt-10">
        <div class="justify-center text-center">
          <a href="https://fiberzy.com" target="_blank">
            <v-img contain max-height="100" src="@/assets/logos/fiberzy.png"></v-img>
          </a>
        </div>
      </v-row>
      <v-row justify="center" align="center" class="mt-5">
        <div class="text-center">
          <div style="border-bottom: solid red 2px;"><i>* Αυτή η ιστοσελίδα δημιουργήθηκε από την <a style="color:blue"
                href="https://fiberzy.com" target="_blank">FiberZy</a>. Χτίσε και εσύ την επόμενη ψηφιακή σου ιδέα μαζί
              μας...</i></div>
        </div>
      </v-row>

      <br>

    </v-container>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert";

export default {
  components: {
    NavBar
  },

  data: () => ({
    hasVoted: false,
    menu: false,
    loading: false,
    selectedCustomer: {
      firstName: "",
      lastName: "",
      email: "",
      mobilePhone: "",
      tripDate: "",
    },
    valid: false,
    tripsWithActiveStatus: [],
    myRules: {
      allFields: [(v) => !!v || "Το πεδίο είναι υποχρεωτικό"],
      selectField: [(v) => v.length > 0 || "Το πεδίο είναι υποχρεωτικό"],
    },
  }),

  beforeMount() { },

  mounted() {
    this.fetchAllTripsByActiveStatus();
  },

  methods: {
    ...mapActions("customers", ["createCustomer"]),
    ...mapActions("trips", ["fetchAllTripsByActiveStatusAction"]),

    async fetchAllTripsByActiveStatus() {
      await this.fetchAllTripsByActiveStatusAction(1);
      this.tripsWithActiveStatus = this.getAllTripsByActive;
    },

    async saveCustomer() {
      try {
        this.loading = true;
        await this.createCustomer(this.selectedCustomer);
        swal(
          "Επιτυχής αποστολή μηνύματος. Παρακαλώ μπορείτε να κλείσετε την συγκεκριμένη σελίδα."
        );
        this.loading = false;
      } catch {
        this.loading = false;
        swal(
          "Υπήρξε κάποιο πρόβλημα. Παρακαλώ επικοινωνήστε με τον διαχειριστή της σελίδας."
        );
      }
      this.hasVoted = true;
      // this.$router.push("/students");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
  },

  computed: {
    ...mapGetters("trips", ["getAllTrips", "getAllTripsByActive"]),
    formatedDate: {
      get: function () {
        return null;
      },
      set: function (val) {
        return (this.selectedCustomer.tripDate = this.formatDate(val));
      },
    },
  },
};
</script>


<style scoped></style>
