import axios from 'axios';
import store from '@/state/store.js'

export default {
    namespaced: true,
    state: {
        allStatementsByPersonId: [],
        selectedStatement: ""
    },

    mutations: {
        setSelectedStatement(state, selectedStatement) {
            state.selectedStatement = selectedStatement;
        },
        setAllStatementsByPersonId(state, allStatementsByPersonId) {
            state.allStatementsByPersonId = allStatementsByPersonId;
        },
        setNewStudent(state, newStudentDetails) {
            state.selectedStudent = newStudentDetails;
        },
        updateStatement(state, updatedStatementData) {
            state.selectedStatement = updatedStatementData;
        },
        createStatement(state, createdStatementData) {
            state.createdStatement = createdStatementData;
        }
    },

    actions: {

        async fetchStatementById({ commit }, statementId) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
            axios
                .get("/statements/" + statementId)
                .then(response => {
                    console.log(response.data);
                    commit("setSelectedStatement", response.data);
                })
        },

        fetchAllStatementsByPersonId: ({ commit }, personId) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/statements/person/" + personId)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setAllStatementsByPersonId", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        updateStatement({ commit }, statementDetails) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .post('/statements/' + statementDetails.id, statementDetails)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('updateStatement', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createStatement({ commit }, statementDetails) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .post('/statements/', statementDetails)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('createStatement', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }

    },

    getters: {
        getAllStatementsByPersonId: state => state.allStatementsByPersonId,
        getStatementById: state => state.selectedStatement,
    },
}