var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-lg-center",staticStyle:{"position":"relative","top":"10%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5","sm":"5","md":"5","lg":"5"}}),_c('v-col',{attrs:{"cols":"2","sm":"2","md":"2","lg":"2"}},[_c('v-img',{attrs:{"src":require("@/assets/ypogeiaathinalogo.jpg")}})],1),_c('v-col',{attrs:{"cols":"5","sm":"5","md":"5","lg":"5"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","sm":"3","md":"3"}}),_c('v-col',{attrs:{"cols":"1","sm":"6","md":"6"}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',[_vm._v("Alter Exploring Login")])]),_c('v-card-text',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('ValidationProvider',{attrs:{"name":"username","rules":{
                  regex: /^[A-Za-z0-9!@#$%]*$/,
                  required: true,
                  max: 15,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"prepend-icon":"mdi-account","name":"Username","label":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":{
                  regex: /^[A-Za-z0-9!@#\$%]*$/,
                  required: true,
                  max: 10,
                  min: 4,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"prepend-icon":"mdi-lock","name":"Password","label":"Password","type":"password","autocomplete":"on"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('v-card-actions',[_c('v-btn',{attrs:{"loading":_vm.loadingLogin,"color":"rgb(102, 102, 51, 0.5)","type":"submit","primary":"","large":"","block":""}},[_vm._v("Login")])],1)],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"1","sm":"3","md":"3"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }